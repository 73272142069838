import React from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import ErrorListResults from 'src/components/error/ErrorListResults';
// import CustomerListToolbar from '../components/company/CustomerListToolbar';
// import customers from '../__mocks__/customers';

const ErrorList = () => {
  const [errors, setErrors] = React.useState();

  React.useEffect(async () => {
    try {
      // handleToggle(true);
      await axios
        .get('saas/errors')
        .then((response) => {
          console.log(response);
          setErrors(response.data.data);
          // setNewLearner({
          //   ...newLearner,
          //   name: "",
          //   email: "",
          // });
          // handleToggle(false);
          // setAllLearners(response.data.data);
          // setMessage(response.data.message);
        });
    } catch (e) {
      // handleToggle(false);
      if (e.response && e.response.data) {
        // setMessage(e.response.data.message);
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Error Logs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            {errors && <ErrorListResults errors={errors} />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ErrorList;
