import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import Logo from './Logo';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const envKey = localStorage.getItem('appMode');

  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModeChange = () => {
    const newMode = envKey === 'prod' ? 'dev' : 'prod';
    localStorage.setItem('appMode', newMode);
    window.location.reload();
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <FormControlLabel
          control={
            <Switch
              color="warning"
              onChange={handleModeChange}
              checked={envKey === 'prod'}
            />
          }
          label="Production"
          labelPlacement="start"
        />
        <MenuItem onClick={logOut}>Logout</MenuItem>

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
