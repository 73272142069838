// import { useState } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  // Avatar,
  Box,
  Card,
  // Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
// import getInitials from '../../utils/getInitials';

const ErrorListResults = ({ errors, ...rest }) => {
  console.log(errors);
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === errors.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < errors.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell>Api Host</TableCell>
                <TableCell>Api Path</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errors
                && errors.map((error) => (
                  <TableRow
                    hover
                    key={error._id}
                    // selected={selectedCustomerIds.indexOf(error.id) !== -1}
                  >
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(error.id) !== -1}
                      onChange={(event) => handleSelectOne(event, error.id)}
                      value="true"
                    />
                  </TableCell> */}
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        {/* <Avatar src={error.avatarUrl} sx={{ mr: 2 }}>
                        {getInitials(error.name)}
                      </Avatar> */}
                        <Typography color="textPrimary" variant="body1">
                          {error.apiHost}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{error.apiPath}</TableCell>
                    <TableCell>
                      {error.message}
                      {/* {`${error.address.city}, ${error.address.state}, ${error.address.country}`} */}
                    </TableCell>
                    {/* <TableCell>{error.phone}</TableCell> */}
                    <TableCell>
                      {error.timeStamp}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* <TablePagination
        component="div"
        count={errors && errors.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
};

ErrorListResults.propTypes = {
  errors: PropTypes.array.isRequired
};

export default ErrorListResults;
