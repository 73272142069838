// eslint-disable-next-line

import axios from 'axios';

const isHandlerEnabled = (config = {}) =>
  Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') &&
  config.handlerEnabled;

const requestHandler = (request) => {
  if (isHandlerEnabled(request.config)) {
    // if isHandlerEnabled is true send authorization token in headers
    if (localStorage.getItem('7-star-training-admin')) {
      request.headers.Authorization = localStorage.getItem(
        '7-star-training-admin'
      );
    } else if (sessionStorage.getItem('7-star-training-admin-temp')) {
      request.headers.Authorization = sessionStorage.getItem(
        '7-star-training-admin-temp'
      );
      sessionStorage.removeItem('7-star-training-admin-temp');
    }
  }
  return request;
};

const env = {
  prod: 'https://app.7startraining.com/api/',
  dev: 'https://startraining.herokuapp.com/api/'
};

const Axios = () => {
  // set the default url part of axios(common part of url in all apis)
  const envKey = localStorage.getItem('appMode');
  if (!envKey) {
    localStorage.setItem('appMode', 'dev');
    axios.defaults.baseURL = process.env.REACT_APP_BASEURL_DEV;
  } else {
    axios.defaults.baseURL =
      envKey == 'dev'
        ? process.env.REACT_APP_BASEURL_DEV
        : process.env.REACT_APP_BASEURL_PROD;
  }

  // axios.defaults.baseURL =
  // envKey == 'dev'
  //   ? process.env.REACT_APP_BASEURL_DEV
  //   : process.env.REACT_APP_BASEURL_PROD;

  // axios.defaults.baseURL = env[envKey] ? env[envKey] : env.prod;

  // Request Interceptors(before we send a request)
  axios.interceptors.request.use(
    (req) => {
      // check if the user has an internet connection
      if (navigator.onLine) {
        // if user has internet connection then send request
        requestHandler(req);
        // store.dispatch({ type: SHOW_LOADER })
      } else {
        // internet connectivity error
        throw new axios.Cancel(
          'Operation cancelled due to disconnectivity of Internet'
        );
      }
      return req;
    },
    (error) => Promise.reject(error)
  );

  // response interceptor (when responseis sent back)
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      // setTimeout(() => {
      //     store.dispatch({ type: HIDE_LOADER })
      // })
      if (axios.isCancel(error)) {
        console.log('Canceled');
      } else {
        console.log('Error is', error);
        if (error.message === 'Network Error') {
          // store.dispatch(showToastMessage(''Server Is Down, Try Latter.''))
          alert('Server Is Down, Try Latter.');
        } else if (
          error.message === 'Request failed with status code 401' ||
          error.message === 'Auth Token Is Not Supplied'
        ) {
          //   refresh token case
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }
      }
      return Promise.reject(error);
    }
  );
};

export default Axios;
