// import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  // Avatar,
  Box,
  Card,
  // Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  // TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
// import getInitials from '../../utils/getInitials';

const CustomerListResults = ({ companies, ...rest }) => {
  console.log(companies);
  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === companies.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < companies.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell> */}
                <TableCell>Company Name</TableCell>
                <TableCell>Customer Email</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Subs. Status</TableCell>
                <TableCell>Subs. Plan</TableCell>
                <TableCell>Registration date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies
                && companies.map((company) => (
                  <TableRow
                    hover
                    key={company._id}
                    // selected={selectedCustomerIds.indexOf(company.id) !== -1}
                  >
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(company.id) !== -1}
                      onChange={(event) => handleSelectOne(event, company.id)}
                      value="true"
                    />
                  </TableCell> */}
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        {/* <Avatar src={company.avatarUrl} sx={{ mr: 2 }}>
                        {getInitials(company.name)}
                      </Avatar> */}
                        <Typography color="textPrimary" variant="body1">
                          {company.rawCompanyName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{company.email}</TableCell>
                    <TableCell>
                      {company.name}
                    </TableCell>
                    <TableCell>{company.subscription.status}</TableCell>
                    <TableCell>{company.subscription.plan}</TableCell>
                    {/* {company.subscription.status === 'active'
                      ? company.subscription.plan
                      : company.subscription.status} */}
                    <TableCell>
                      {moment(company.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* <TablePagination
        component="div"
        count={companies && companies.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>
  );
};

CustomerListResults.propTypes = {
  companies: PropTypes.array.isRequired
};

export default CustomerListResults;
